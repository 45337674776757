/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS520M
 * 화면 설명: 모바일청약 법인명의 개인보험계약관계자 확인
 */
<!--
기존 : MSPPS120M
[2024.03.08 수정사항]
0. 변경한 정보는 고객카드에... 영역 class명(bgcolor-1) 삭제 : w100 bd-b-Ty1 bd-T-Ty1 pal1624 bgcolor-1  -> w100 bd-b-Ty1 bd-T-Ty1 pal1624
1. 그룹화 class명 추가 : pb82 -> card-list-wrap list-ty2 pb82 추가
2. class명 변경 : pal2024 lastdiv-full fexTy5 bd-b-Ty1 -> pal2024 firstdiv-full fexTy3-1
3. <template #title> 및 <template #btn> 영역 추가 및 변경
4. class명 변경 : con-area pt30 pb50 bd-b-Ty1 -> con-area pt10 pb30
5. 주민등록번호 class명 변경 : verify-birth mt20 -> verify-birth
6. 경계영역 추가 <div class="full48 h10px bgcolor-3 mt30" />
7. 청약작성자 타이틀 영역 수정
8. <msp-expand ...>에 펼침 옵션 expanded 추가
9. 하단 버튼 class명 추가 : ns-btn-round blue -> ns-btn-round blue fs16rem

[2024.03.25 수정사항]
1. pb82 bgcolor-1 과 card-list-wrap list-ty2 로 나눠서 감싸기
2. pb82 bgcolor-1 영역 alignV="start"를 alignH="center" 
-->
<template>
  <ur-page-container class="msp" title="정보확인" :show-title="true" type="subpage" :topButton="true"
    @on-header-left-click="fn_HeaderBackBtnHandler">

    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-ps-wrap">      
      <div class="w100 bd-b-Ty1 bd-T-Ty1 pal1624"><!-- // class명(bgcolor-1) 삭제 2024.03.08 -->
        <span class="fs14rem crTy-bk7">수익자가 계약자(법인)가 아닌 상속인으로 별도 지정시, 모바일청약 진행단계에서 보험가입목적(자산운용 혹은 복리후생)을 올바르게 입력해 주시기 바랍니다.</span>
      </div>
      <ur-box-container alignH="center" componentid="" direction="column" class="pb82 bgcolor-1"><!-- // alignV="start"를 alignH="center" 변경 2024.03.25 -->
        <ur-box-container direction="column" alignV="start" class="card-list-wrap list-ty2"><!-- // 그룹화 class명 card-list-wrap list-ty2 추가 -->        
          <!-- 주피보험자 -->
          <!-- msp-expand 에 맨 첫번째 값만 펼침 일 겨우 첫번째에 expanded 넣어주세요! -->
          <msp-expand btn-area-first title-first expanded expand-only-btn class="mo-list-expand w100" btn-area-class="pal2024 firstdiv-full fexTy3-1"> 
            <template #title>
              <div class="list-item__contents overflow-v">
                <div class="list-item__contents__title overflow-v">
                  <div class="badge-area">
                    <!-- <mo-badge class="badge-sample-badge green" text="" shape="status">계약자</mo-badge>
                    <mo-badge class="badge-sample-badge blue2" text="" shape="status">피보험자</mo-badge>                   -->
                    <mo-badge class="badge-sample-badge blue2" text="" shape="status">주피</mo-badge>
                    <!-- <mo-badge class="badge-sample-badge blue" text="" shape="status">종피</mo-badge>
                    <mo-badge class="badge-sample-badge green2" text="" shape="status">예금주</mo-badge>
                    <mo-badge class="badge-sample-badge gray" text="" shape="status">친권자1</mo-badge>
                    <mo-badge class="badge-sample-badge gray" text="" shape="status">친권자2</mo-badge>
                    <mo-badge class="badge-sample-badge purple2" text="" shape="status">수익자</mo-badge>
                    <mo-badge class="badge-sample-badge black" text="" shape="status">FC</mo-badge> 
                    <mo-badge class="badge-sample-badge gray2" text="" shape="status">자녀</mo-badge> -->
                  </div>  

                  <!-- 고객 미선택 case -->
                  <!-- <div class="ml10">
                    <span class="fwn fs19rem crTy-bk7">선택하세요</span>
                  </div>  -->
                  <!-- // 고객 미선택 case -->

                  <!-- 고객 선택 case -->
                  <div class="ml10">
                    <div>
                      <span class="name">{{ lv_insured.custNm }}</span>
                      <!-- <mo-badge class="badge-sample-badge sm ml10 lightgreen" text="" shape="status">계약자</mo-badge>
                      <mo-badge class="badge-sample-badge sm ml10 lightblue" text="" shape="status">주피</mo-badge>
                      <mo-badge class="badge-sample-badge sm ml10 lightblue2" text="" shape="status">종피</mo-badge> -->
                    </div>
                    <div class="w100 mt20 fexTy5">
                      <mo-button ref="resntNmCheck" class="ns-btn-round h28 blue-line" :disabled="isResntNmCheck" @click.stop="fn_resntNmCheck()">대표자 확인</mo-button>
                    </div>
                    <div class="w100calc50 mt5">
                      <div class="fwn crTy-orange2" v-if="!isResntNmCheck">대표자여부를 확인 해주세요.</div>
                      <div v-if="isResntNmCheck"><mo-icon icon="msp-is-checked" class="fs26"/><span class="fwn crTy-bk7">확인되었습니다.</span></div>
                    </div>
                    <!-- <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment w100calc50 mt20">
                      <mo-segment-wrapper solid primary v-model="firstItem1" class="btn-type-wrap h28">
                        <mo-segment-button class="mr10" value="1">알림톡</mo-segment-button>
                        <mo-segment-button class="mr10" value="2">바로열기</mo-segment-button>
                      </mo-segment-wrapper>
                    </ur-box-container>   -->
                  </div>  
                  <!-- // 고객 선택 case -->
                </div>
              </div>
            </template>
            <template #btn>
              <mo-button class="link-arrow">
                <mo-icon icon="expand_menu" class="expand__icon" />                  
                <span class="open">접기</span>
                <span class="close">열기</span>
              </mo-button>
            </template>
            <template #content>
              <div class="con-area pt10 pb30">
                <div class="w100">
                  <div class="label-title">성명</div>
                  <mo-text-field  class="form-input-name full" v-model="lv_insured.custNm" readonly underline placeholder="성명" />
                </div>
                <div class="w100 mt20">
                  <div class="label-title">연령</div>
                  <mo-text-field class="form-input-name full" v-model="lv_insured.age + '세'" readonly underline placeholder="연령" />
                </div>
                <div class="w100 mt20">
                  <div class="label-title">주민등록번호</div>
                  <div class="verify-birth">
                    <mo-text-field ref="error" underline class="birth-input1 form-input-name w100" v-model="lv_insured.custRrnId1" readonly placeholder="생년월일" error-message="Error message here"/>
                    <span>-</span>
                    <mo-text-field ref="error" underline class="birth-input2 form-input-name w100" v-model="lv_insured.custRrnId2" readonly placeholder="●  ●  ●  ●  ●  ●" error-message="Error message here"/>
                  </div>
                </div>
                <div class="w100 mt20">
                  <div class="label-title">휴대폰번호</div>
                  <mo-text-field  class="form-input-name full" v-model="lv_insured.custCelno" readonly underline placeholder="휴대폰번호" />
                </div>
                <ur-box-container alignV="start" componentid="" direction="row" class="insert-area full48 pb0">
                  <mo-button class="ns-btn-round rounded" @click="fn_showTSSCM122D(lv_insured.chnlCustId)">수정</mo-button>
                </ur-box-container>
              </div>
            </template>
          </msp-expand>

          <!-- 계약자 -->
          <msp-expand btn-area-first title-first expanded expand-only-btn class="mo-list-expand w100" btn-area-class="pal2024 firstdiv-full fexTy3-1"> 
            <template #title>
              <div class="list-item__contents overflow-v">
                <div class="list-item__contents__title overflow-v">
                  <div class="badge-area">
                    <mo-badge class="badge-sample-badge green" text="" shape="status">계약자</mo-badge>
                  </div>  

                  <!-- 고객 미선택 case -->
                  <!-- <div class="ml10">
                    <span class="fwn fs19rem crTy-bk7">선택하세요</span>
                  </div>  -->
                  <!-- // 고객 미선택 case -->

                  <!-- 고객 선택 case -->
                  <div class="ml10">
                    <div>
                      <span class="name">{{ lv_contr.custNm }}</span>
                    </div>
                    <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment w100calc50 mt20">
                      <mo-segment-wrapper solid primary v-model="urlTrnsTypContr[0].key" class="btn-type-wrap h28">
                        <mo-segment-button class="mr10" value="K">알림톡</mo-segment-button>
                          <!-- 툴팁 추가 //2025.01 툴팁추가  -->
                        <div class="tooltipWrap">
                           <mo-segment-button class="mr10" value="T" v-if="btnView">바로열기</mo-segment-button>
                           <div class="chat-bubble type-M1 type2" v-if="showHideTooltip===true && btnView === true" @click="showHideTooltip=false">
                              FC 태블릿이나 휴대폰에서<br>고객과 함께 진행 할 수 있어요.
                            <mo-icon icon="msp-close-bold" class="chat-bubble-close" />
                          </div>
                        </div>
                        <!-- 툴팁 추가 끝 //2025.01 툴팁추가  -->
                      </mo-segment-wrapper>
                    </ur-box-container>  
                  </div>  
                  <!-- // 고객 선택 case -->
                </div>
              </div>
            </template>
            <template #btn>
              <mo-button class="link-arrow">
                <mo-icon icon="expand_menu" class="expand__icon" />                  
                <span class="open">접기</span>
                <span class="close">열기</span>
              </mo-button>
            </template>
            <template #content>
              <div class="con-area pt10 pb30">
                <div class="w100">
                  <div class="label-title">단체명</div>
                  <mo-text-field  class="form-input-name full" v-model="lv_contr.custNm" readonly underline placeholder="단체명" />
                </div>
                <div class="w100 mt20">
                  <div class="label-title">사업자등록번호</div>
                  <mo-text-field  class="form-input-name full" v-model="lv_contr.custRrnId1" readonly underline placeholder="사업자등록번호" />
                </div>

                <div class="full48 h10px bgcolor-3 mt30" />
                
                <div class="w100 mt30">
                  <div class="contain-title">청약작성자</div>
                </div>
                <div class="w100 mt10">
                  <div class="label-title">성명</div>
                  <mo-text-field  class="form-input-name full" v-model="lv_insured.custNm" readonly underline placeholder="성명" />  
                </div>
                <div class="w100 mt20">
                  <div class="label-title">연령</div>
                  <mo-text-field  class="form-input-name full" v-model="lv_insured.age + '세'" readonly underline placeholder="연령" />
                </div>

                <div class="w100 mt20">
                  <div class="label-title">주민등록번호</div>
                  <div class="verify-birth">
                    <mo-text-field ref="error" underline class="birth-input1 form-input-name w100" v-model="lv_insured.custRrnId1" readonly placeholder="생년월일" error-message="Error message here"/>
                    <span>-</span>
                    <mo-text-field ref="error" underline class="birth-input2 form-input-name w100" v-model="lv_insured.custRrnId2" readonly placeholder="●  ●  ●  ●  ●  ●" error-message="Error message here"/>
                  </div>
                </div>

                <div class="w100 mt20">
                  <div class="label-title">휴대폰번호</div>
                  <mo-text-field  class="form-input-name full" v-model="lv_insured.custCelno" readonly underline placeholder="휴대폰번호" />
                </div>
                
              </div>       
            </template>
          </msp-expand>

          <!-- FC -->
          <msp-expand btn-area-first title-first expanded expand-only-btn class="mo-list-expand w100" btn-area-class="pal2024 firstdiv-full fexTy3-1"> 
            <template #title>
              <div class="list-item__contents overflow-v">
                <div class="list-item__contents__title overflow-v">
                  <div class="badge-area">
                    <mo-badge class="badge-sample-badge black" text="" shape="status">FC</mo-badge>
                  </div>  

                  <!-- 고객 미선택 case -->
                  <!-- <div class="ml10">
                    <span class="fwn fs19rem crTy-bk7">선택하세요</span>
                  </div>  -->
                  <!-- // 고객 미선택 case -->

                  <!-- 고객 선택 case -->
                  <div class="ml10">
                    <div>
                      <span class="name">{{ lv_fc.fcNm }}</span>
                    </div>
                    <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment w100calc50 mt20">
                      <mo-segment-wrapper solid primary v-model="urlTrnsTypFC[0].key" class="btn-type-wrap h28">
                        <mo-segment-button class="mr10" value="K">알림톡</mo-segment-button>
                        <!-- 툴팁 추가 //2025.01 툴팁추가  -->
                        <div class="tooltipWrap">
                          <mo-segment-button class="mr10" value="T">바로열기</mo-segment-button>
                          <div class="chat-bubble type-M1 type2" v-if="showHideTooltip===true" @click="showHideTooltip=false">
                            FC 태블릿이나 휴대폰에서<br>고객과 함께 진행 할 수 있어요.
                            <mo-icon icon="msp-close-bold" class="chat-bubble-close" />
                          </div>
                        </div>
                        <!-- 툴팁 추가 끝 //2025.01 툴팁추가  -->

                      </mo-segment-wrapper>
                    </ur-box-container>  
                  </div>  
                  <!-- // 고객 선택 case -->
                </div>
              </div>
            </template>
            <template #btn>
              <mo-button class="link-arrow">
                <mo-icon icon="expand_menu" class="expand__icon" />                  
                <span class="open">접기</span>
                <span class="close">열기</span>
              </mo-button>
            </template>
            <template #content>
              <div class="con-area pt10 pb30">
                <div class="w100">
                  <div class="label-title">성명</div>
                  <mo-text-field  class="form-input-name full" v-model="lv_fc.fcNm" readonly underline placeholder="성명" />
                </div>
                <div class="w100 mt20">
                  <div class="label-title">휴대폰번호</div>
                  <mo-text-field  class="form-input-name full" v-model="lv_fc.fcCelno" readonly underline placeholder="휴대폰번호" />
                </div>
              </div>       
            </template>
          </msp-expand>

        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
    <!-- content 영역 -->

    <template>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue fs16rem" v-if="authPass" @click="fn_mobpsPblProc('N')">다음 (알림X,검증X)</mo-button>
          <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue fs16rem" @click="fn_mobpsPblProc('Y')" >다음</mo-button>
        </div>
      </ur-box-container>
    </template>
   
  </ur-page-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import { createScrollAnimateInstance } from '@/utils/scroll/scroll-animate'
import Msg from '@/systems/webkit/msg/msg'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import PSCommUtil from '@/ui/ps/comm/PSCommUtil'
import PSDateUtil from '@/ui/ps/comm/PSDateUtil'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 
import PSAmlUtil from '@/ui/ps/comm/PSAmlUtil'
import MSPCM300P from '@/ui/cm/MSPCM300P'
import PSElstUtil from '@/ui/ps/comm/PSElstUtil' // 전자서명 시작 관련 서비스 유틸


export default {

  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS520M', 
  screenId: 'MSPPS520M', 
  components: {
    'MSPCM300P': MSPCM300P,
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
  },//props

  /***********************************************************************************
  * 팝업일때 팝업 활성 형태 속성 
  * - 풀 팝업 형태 일때 mode 값 설정
  ***********************************************************************************/
  /*
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  */

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {
      title: '모바일청약',
      lv_InputData: {}, // 발행 데이터
      hoshInfoList: '', // 계약자/피보험자 정보
      planInfoData: '', // 상품정보
      jsonString: '',
      contrInsrdSameYn: 'N', // 계피상이여부
      isSendKakao: 'Y', // 카카오 알림톡 발송여부
      amlBnfrInfoList : {},
      tsscm122DCnsltNo: '',
      tsscm122DChnlCustId: '',
      showHideTooltip: true, //2025.01 툴팁추가 

      // 계약자
      lv_contr: {
        custNm: '',
        age: '',
        custRrnId: '',
        custCelno: '',
        chnlCustId: '',
        chnlCstgrId: '',
        showNameTooltip: false // 이름이 4자이상인 경우 toolTip표시
      },

      // 피보험자
      lv_insured: {
        custNm: '',
        age: '',
        custRrnId: '',
        custRrnId1: '',
        custRrnId2: '',
        chnlCustId: '',
        chnlCstgrId: '',
        showNameTooltip: false // 이름이 4자이상인 경우 toolTip표시
      },

      lv_fc: { // fc
        fcNm: '', // FC 이름
        fcCelno: '',
        showNameTooltip: false // 이름이 4자이상인 경우 toolTip표시
      },

      urlTrnsTypList: [ // URL전송유형 목록
        {
          key: 'K',
          label: '알림톡'
        },
        {
          key: 'T',
          label: '바로열기'
        }
      ],

      urlTrnsTypContr: [{ // 계약자 URL전송유형
        key: 'K',
        label: '알림톡'
      }],

      urlTrnsTypFC: [{ // FC URL전송유형
        key: 'K',
        label: '알림톡'
      }],

      //isMobpsPblEnd: false, // 모바일청약발행Confirm표시
      mobpsPblEndMessage: '모바일청약을 발송하였습니다.<br>※ [진행조회]에서 진행현황을 조회하실 수 있습니다.',

      isResntNmCheck: false, // 대표자명 확인여부
      isResntNmCheckMessage: '주피보험자가 법인대표와 일치하지 않습니다.<br>가입설계를 확인 후 다시 발행해 주시기 바랍니다.',

      standardAge19: 19, // 기준 나이 19 / 미성년자
      isStandardAge19CheckMessage: '법인대표가 미성년자인경우 모바일청약을<br>진행할 수 없습니다.',

      //isAlertConfirm: false,
      isAlertConfirmMessage: '',
      btnView: true, // 공동대표인경우 계약자 '바로열기' 숨김 (ASR240800968 24.10.22)
      CorpInfo: {}
    }
  },//data

  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  
  //router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  
  //beforeRouteEnter (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteEnter

  //router에 의해 화면을 벗어날때 호출 
  //beforeRouteLeave (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave


 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {
    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)
    
    this.lv_InputData = this.$route.params
    //console.log('★★★★ MSPPS520M 받은 파라미터!!')
    //console.log(this.lv_InputData)

    // 검증계면 인증 패스 로직 유효 local/development/stage/production
    this.authPass = (process.env.NODE_ENV !== 'production')

    if (this.$route.params) {
      this.lv_InputData = this.$route.params
    }

    // 초기정보 조회
    let pId = this.lv_InputData.entplId
    this.jsonString = JSON.stringify({tmodel: {searchCriteria: {zaPlanId: pId}}, vuchId: ' ', dpstrInfo: {chnlCustId: ''}})
    PSElstUtil.fn_EltrnSignBaseInfo('txTSSPS13S2', this.jsonString, this.fn_ServiceDataResultHandler)
      
  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
    return
  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
     // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

  },//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {},//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { return this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {},//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {
    
  /*---------------------------------------------------------------------------------
  * UI 이벤트 핸들러 영역
  ---------------------------------------------------------------------------------*/

    /******************************************************************************
    * Function명  : fn_DevicBackBtnHandler
    * 설명        : 디바이스 Back Button 핸들러
    ******************************************************************************/
    fn_DevicBackBtnHandler() {
      if(PSCommUtil.fn_IsActiveModal()) return
      this.fn_HeaderBackBtnHandler()
    },

    /******************************************************************************
    * Function명  : fn_HeaderBackBtnHandler
    * 설명        : 샘플 서비스 호출 함수
    ******************************************************************************/
    fn_HeaderBackBtnHandler() {
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
       properties: {
         title: '알림',
         content: '모바일청약 진행을 종료하고 청약서발행현황으로 이동하시겠습니까?',
         title_pos_btn: "확인",
         title_neg_btn: "취소"
       },
       listeners: {
         onPopupConfirm: () => {
           this.$bottomModal.close(this.lv_AlertPop);
           this.$MenuManager.fn_RouterPushSrnId('MSPPS220M')
         },
         onPopupClose: () => {
           this.$bottomModal.close(this.lv_AlertPop);
         }
        }
      })
    },// fn_HeaderBackBtnHandler

     /******************************************************************************
    * Function명 : fn_moveMobslList
    * 설명       : 모바일청약 목록 화면 이동
    ******************************************************************************/
    fn_moveMobslList () {
      // 모바일청약 Tab으로 이동
      let pSrnId = 'MSPPS230M'
      let pParams = {}
      pParams.tabMenu = pSrnId
      pParams.idx = 1 // 중단건 목록조회 Tab
      pParams.vuchId = this.lv_InputData.moDCDocuMngVO.eltrnDoctId 
      
      let t_RouterParam = {name: pSrnId, params: pParams}
      window.vue.getInstance('router').push(t_RouterParam)
    },

  /*---------------------------------------------------------------------------------
  * Data Service 핸들러 영역
  ---------------------------------------------------------------------------------*/
    /******************************************************************************
    * Function명 : fn_ServiceDataResultHandler
    * 설명       : 전자서명 초기 데이타 호출 결과 컨트롤 함수
    ******************************************************************************/
    fn_ServiceDataResultHandler: function (event) {
      //console.log('★★★★ event.List!!')
      //console.log(event)
      if (event.hoshInfoList) {
        this.hoshInfoList = event.hoshInfoList // 계약자/피보험자 정보
        this.planInfoData = event.planInfoData // 상품정보

        // 계약자 정보 설정 hoshInfoList[0]
        this.lv_contr.custNm = this.hoshInfoList[0].insrdNm // 계약자 이름
        if (this.lv_contr.custNm.length > 4) { // 계약자 이름이 길어질 경우 tooltip표시 여부 확인
          this.lv_contr.showNameTooltip = true
        }
        this.lv_contr.age = this.hoshInfoList[0].age // 계약자 나이
        this.lv_contr.custRrnId = this.hoshInfoList[0].custRrnId // 피보험자 주민번호
        this.lv_contr.custRrnId1 = this.$bizUtil.bmanNoFormat(this.lv_contr.custRrnId)

        // 피보험자 정보 설정 hoshInfoList[1]
        this.lv_insured.custNm = this.hoshInfoList[1].insrdNm // 피보험자 이름
        if (this.lv_insured.custNm.length > 4) { // 계약자 이름이 길어질 경우 tooltip표시 여부 확인
          this.lv_insured.showNameTooltip = true
        }
        this.lv_insured.age = this.hoshInfoList[1].age // 피보험자 나이
        this.lv_insured.custRrnId = this.hoshInfoList[1].custRrnId // 피보험자 주민번호
        this.lv_insured.custRrnId1 = this.hoshInfoList[1].custRrnId.substring(0, 6) // 피보험자 주민번호 앞자리
        this.lv_insured.custRrnId2 = this.hoshInfoList[1].custRrnId.substring(6, 7) + '******' // 피보험자 주민번호 뒷 한자리 + 마스킹
        this.lv_insured.custCelno = this.hoshInfoList[1].detail.celno // 계약자 전화번호

        this.lv_insured.chnlCustId = this.hoshInfoList[1].detail.chnlCustId // 피보험자 채널고객ID
        this.lv_insured.chnlCstgrId = this.hoshInfoList[1].detail.chnlCstgrId // 피보험자 채널그룹ID

        this.contrInsrdSameYn = 'Y' // 계피상이

        // fc정보 조회
        this.fn_selFcInfo()

        if (event.bnfrInfoList) { // 사망수익자 셋팅
          this.amlBnfrInfoList = event.bnfrInfoList
        }
        this.fn_selF1MDI0074() // (ASR240800968 24.10.22 )
      } else { // if (hoshInfoList) { 종료
        console.log('#### fn_ServiceDataResultHandler 오류')
      }
    },

    /******************************************************************************
    * Function명 : fn_selFcInfo
    * 설명       : FC정보조회
    *            : FC전화번호는 getStore('userInfo')에 가지고 있지 않음
    ******************************************************************************/
    fn_selFcInfo () {
      let lv_Vm = this
      let pParams = {userEno: this.getStore('userInfo').getters.getUserInfo.userId}
      const trnstId = 'txTSSBC20S3'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          let fc_UserVo = response.body
          lv_Vm.lv_fc.fcNm = fc_UserVo.userNm
          if (lv_Vm.lv_fc.fcNm.length > 4) { // FC 이름이 길어질 경우 tooltip표시 여부 확인
            lv_Vm.lv_fc.showNameTooltip = true
          }
          // 전화번호는 00011112222 형태이기때문에, -을 추가하기 위해서 util 호출
          lv_Vm.lv_fc.fcCelno = lv_Vm.$bizUtil.telNoWithHyphen(fc_UserVo.celno.trim())
          lv_Vm.$forceUpdate()
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
    * Function명 : fn_mobpsPblProc
    * 설명       : 모바일청약 발행 처리
    ******************************************************************************/
    async fn_mobpsPblProc (isSendKakao) {
      // 알림톡 발송여부 지정
      this.isSendKakao = isSendKakao

      // 운영X, 대표확인 통과
      if (this.authPass && isSendKakao === 'N') {
        this.isResntNmCheck = true
      } else {
        // 대표자이름 검증 확인
        if (!this.isResntNmCheck) {
          document.getElementById('scroll_MSPPS520M').scrollTop = this.$refs['resntNmCheck'].$el.offsetTop - 60 // 스크롤이동
          this.isAlertConfirmMessage = '피보험자의 대표자여부가 확인되지 않았습니다.'
          //this.isAlertConfirm = true
          this.fn_IsAlertConfirmMessage(this.isAlertConfirmMessage)
          return
        }
      }

      let lv_Vm = this
      let param = {}
      const trnstId = 'txTSSPS50P6' //AML대응 'txTSSPS50P1'
      const auth = 'I'

      param = {
        // TTSD00001
        'vuchId': '', // 영수증ID
        'elstPlanId': '', // 전자서명설계ID
        'clctCnsltNo': '', // 모집컨설턴트번호
        'contrInsrdSameYn': '', // 계약자피보험자동일여부
        'mnisdMiorYn': '', // 주피보험자미성년자여부
        'unchYn': '', // 태아여부
        'moblCswfYn': '', // 모바일완전판매여부
        'insrOfrYmd': '', // 보험청약일자
        'insrdTrtyFgr': '', // 피보험자특약수
        'aisdTrtyFgr': '', // 종피보험자특약수
        'secusFrmCd': '', // 증권양식코드
        'untyCovrInsrTypCd': '', // 통합보장보험유형코드
        'ptclrPrdtTypCd': '', // 특이상품유형코드
        'kliaInsrScCd': '', // 생보협회보험구분코드
        'pdinsRltnCstuCd': '', // 상품보험관계구성코드
        'contrDpstrSameYn': '', // 계약자예금주동일여부
        'insrdDpstrSameYn': '', // 피보험자예금주동일여부
        'dpstrIdpnYn': '', // 예금주독립여부

        // TTSD00002
        'elstPartnNo': '', // 전자서명파트너번호
        'elstInsrdPartnNo': '', // 전자서명피보험자파트너번호
        'mobslChnlCustId': '', // 모바일영업채널고객ID
        'mobslChnlCstgrId': '', // 모바일영업채널고객그룹ID
        'insrdChnlCustId': '', // 피보험자채널고객ID
        'mnContrNm': '', // 주계약자명
        'insrdNm': '', // 피보험자명
        'prcd': '', // 상품코드
        'mnPrdtNm': '', // 주상품명
        'contPrm': '', // 계약보험료
        'isuMthScCd': '', // 교부방법구분코드
        'aftRctmHopYn': '', // 후입금희망여부
        'coprClctYn': '', // 공동모집여부
        //AML 대응
        'amlContrPartnNo' : '' ,  // AML계약자파트너번호
        'amlPrtrPesonPartnNo' : '' , //AML친권자파트너번호
        'amlDthBnfrPartnNo' : '' , //AML사망수익자파트너번호
        'amlDthBnfrChnlCustId' : '' , //AML사망수익자채널고객ID
        'amlDthBnfrCustNm' : '' , //AML사망수익자고객명
        

        // TTSD02003
        'jsonInputData': '', // OZ jsonInputData

        'isSendKakao': this.isSendKakao // 카카오 알림톡 발송여부
      }

      // TTSD00001 : TSSPS110M.fn_InsEltrnSignPrgInfo
      param.vuchId = this.lv_InputData.moDCDocuMngVO.eltrnDoctId // 영수증ID
      param.elstPlanId = this.lv_InputData.entplId // 전자서명설계ID
      param.clctCnsltNo = this.$MenuManager.fn_GetUserInfoProp('userId') // 모집컨설턴트번호
      param.contrInsrdSameYn = 'N' // 계약자피보험자동일여부
      param.mnisdMiorYn = 'N' // 주피보험자미성년자여부
      param.unchYn = 'N' // 태아여부
      param.moblCswfYn = this.lv_InputData.isMoblPftSale ? 'Y' : 'N' // 모바일완전판매여부

      param.insrOfrYmd = PSDateUtil.fn_CurrentDate() // 보험청약일자
      param.insrdTrtyFgr = (this.lv_InputData.insrdTrtyFgr === null || this.lv_InputData.insrdTrtyFgr === '') ? '0' : String(this.lv_InputData.insrdTrtyFgr) // 피보험자특약수
      param.aisdTrtyFgr = (this.lv_InputData.aisdTrtyFgr === null || this.lv_InputData.aisdTrtyFgr === '') ? '0' : String(this.lv_InputData.aisdTrtyFgr) // 종피보험자특약수
      param.secusFrmCd = (this.lv_InputData.zzSecusFrmId === undefined || this.lv_InputData.zzSecusFrmId === '') ? ' ' : this.lv_InputData.zzSecusFrmId // 증권양식코드
      param.untyCovrInsrTypCd = (this.lv_InputData.zzUntyCoITId === undefined || this.lv_InputData.zzUntyCoITId === '') ? ' ' : this.lv_InputData.zzUntyCoITId // 통합보장보험유형코드
      param.ptclrPrdtTypCd = (this.lv_InputData.zzPtclrPrdTId === undefined || this.lv_InputData.zzPtclrPrdTId === '') ? ' ' : this.lv_InputData.zzPtclrPrdTId // 특이상품유형코드
      param.kliaInsrScCd = (this.lv_InputData.zzKliaPrdtId === undefined || this.lv_InputData.zzKliaPrdtId === '') ? ' ' : this.lv_InputData.zzKliaPrdtId // 생보협회보험구분코드
      // 생보협회상품분류값이 3자리로 넘어오면 첫번째를 자르고 뒤에 두개의 유효값만 사용한다

      param.kliaInsrScCd = (typeof (param.kliaInsrScCd) === 'string') ? String(Number(param.kliaInsrScCd)) : String(param.kliaInsrScCd)
      if (param.kliaInsrScCd.length > 2) {
        param.kliaInsrScCd = param.kliaInsrScCd.substr(1, 2)
      } else if (param.kliaInsrScCd.length === 0 || param.kliaInsrScCd === '0') {
        param.kliaInsrScCd = ' '
      } else if (param.kliaInsrScCd.length === 1) {
        param.kliaInsrScCd = '0' + param.kliaInsrScCd
      }

      param.pdinsRltnCstuCd = (this.lv_InputData.zzPdinsRCCId === undefined || this.lv_InputData.zzPdinsRCCId === '') ? ' ' : this.lv_InputData.zzPdinsRCCId // 상품보험관계구성코드
      param.contrDpstrSameYn = 'Y' // 계약자예금주동일여부
      param.insrdDpstrSameYn = 'N' // 피보험자예금주동일여부
      param.dpstrIdpnYn = 'N' // 예금주독립여부

      param.ernam = this.$MenuManager.fn_GetUserInfoProp('userId') // 생성자id
      param.aenam = this.$MenuManager.fn_GetUserInfoProp('userId') // 변경자id

      // TTSD00002 : TSSPS110M.fn_InsElstEtlInfo
      param.elstPartnNo = this.hoshInfoList[0].partnerId // 전자서명파트너번호
      param.elstInsrdPartnNo = this.hoshInfoList[1].partnerId // 전자서명피보험자파트너번호
      param.mobslChnlCustId = this.hoshInfoList[1].agtFileId // 모바일영업채널고객ID
      param.mobslChnlCstgrId = this.hoshInfoList[1].detail.chnlCstgrId // 모바일영업채널고객그룹ID
      param.insrdChnlCustId = this.hoshInfoList[1].agtFileId // 계약자채널고객ID
      param.mnContrNm = this.hoshInfoList[0].insrdNm // 주계약자명
      param.insrdNm = this.hoshInfoList[1].insrdNm // 피보험자명
      param.prcd = this.planInfoData[0].prcdId || ' ' // 상품코드
      param.mnPrdtNm = this.lv_InputData.moDCDocuMngVO.prdtNm || ' ' // 주상품명
      param.contPrm = this.planInfoData[0].smtotPrm // 계약보험료
      param.isuMthScCd = '02' // 교부방법구분코드 02:지류로 등록
      param.aftRctmHopYn = (this.lv_InputData.zzaftRctmFg === '1') ? 'Y' : 'N' // 후입금희망여부
      param.coprClctYn = this.lv_InputData.coprClctYn ? 'Y' : 'N' // 공동모집여부

      param.dpstrMobslChnlCustId = this.hoshInfoList[1].agtFileId // 예금주모바일영업채널고객ID
      param.dpstrNm = this.hoshInfoList[1].insrdNm // 예금주명
      param.dpstrRltnCd = '01' // 계약자/예금주관계ID / 01:본인, 02:배우자, 03:부모, 04:자녀
      param.crncyCd = this.lv_InputData.moDCDocuMngVO.currencyId // 통화코드

      // 지정대리청구
      param.apcAsgnYn = 'N' // 지정대리청구지정여부
      param.apcChnlCustId = '' // 지정대리청구 모바일영업채널고객ID
      param.apcCustNm = '' // 지정대리청구명
      param.contrRltnCd = '' // 계약자관계코드

      // 피보험자동의대상 Y:계약자와 피보험자가 불일치
      param.insrdCnsntObjYn = 'Y'
      param.insrdCnsntScCd = 'U' // 피보험자동의구분코드, U:미동의

      param.corpNomnPinsYn = 'Y' // 법인명의개인보험여부

      // TTSD02002
      let mobpsRegContPtcpList = []

      /** 계약관계자 구분코드 1:계약자 */
      if (this.urlTrnsContrShow && this.$bizUtil.isEmpty(this.hoshInfoList[1].detail.celno)) {
        this.isMobpsValMessge = '작성자의 휴대폰 번호가 없습니다.'
        //this.isMobpsValCheck = true
        this.fn_IsAlertConfirmMessage(this.isMobpsValMessge)
        return
      }
      let mobpsRegContPtcp = {}
      mobpsRegContPtcp.contPtcpScCd = '1' // 계약관계인구분코드 / 1:계약자
      mobpsRegContPtcp.elstPartnNo = this.hoshInfoList[0].partnerId // 전자서명파트너번호, 법인
      mobpsRegContPtcp.mobslChnlCustId = this.hoshInfoList[1].agtFileId // 모바일영업채널고객ID
      mobpsRegContPtcp.mobslChnlCstgrId = this.hoshInfoList[1].detail.chnlCstgrId // 모바일영업채널고객그룹ID
      mobpsRegContPtcp.contPtcpTelnoEncr = this.$bizUtil.replaceAll(this.hoshInfoList[1].detail.celno, '-', '') // 계약관계인전화번호암호화
      mobpsRegContPtcp.contPtcpRrnEncr = this.hoshInfoList[0].custRrnId // 계약관계인주민등록번호암호화, 사업자등록번호
      mobpsRegContPtcp.mobslChnlCustNm = this.hoshInfoList[0].insrdNm // 모바일영업채널고객명, 사업자명
      mobpsRegContPtcp.MobpsContrRltnScCd = '01' // 모바일청약계약자관계구분코드, 01:본인
      mobpsRegContPtcp.mobpsSignMthCd = this.urlTrnsTypContr[0].key // 모바일청약서명방법코드
      mobpsRegContPtcp.coprPrtrMuagrYn = 'N' // 공동친권합의여부
      mobpsRegContPtcp.kakaoIapDspchSn = '1' // 알림톡알림발송순번

      mobpsRegContPtcpList.push(mobpsRegContPtcp)

      /** 계약관계자 구분코드 2:피보험자 */
      if (this.urlTrnsInsuredShow && this.$bizUtil.isEmpty(this.hoshInfoList[1].detail.celno)) {
        this.isMobpsValMessge = '피보험자의 휴대폰 번호가 없습니다.'
        //this.isMobpsValCheck = true
        this.fn_IsAlertConfirmMessage(this.isMobpsValMessge)
        return
      }
      mobpsRegContPtcp = {}
      mobpsRegContPtcp.contPtcpScCd = '2' // 계약관계인구분코드 / 2:피보험자
      mobpsRegContPtcp.elstPartnNo = this.hoshInfoList[1].partnerId // 전자서명파트너번호
      mobpsRegContPtcp.mobslChnlCustId = this.hoshInfoList[1].agtFileId // 모바일영업채널고객ID
      mobpsRegContPtcp.mobslChnlCstgrId = this.hoshInfoList[1].detail.chnlCstgrId // 모바일영업채널고객그룹ID
      mobpsRegContPtcp.contPtcpTelnoEncr = this.$bizUtil.replaceAll(this.hoshInfoList[1].detail.celno, '-', '') // 계약관계인전화번호암호화
      mobpsRegContPtcp.contPtcpRrnEncr = this.hoshInfoList[1].custRrnId // 계약관계인주민등록번호암호화
      mobpsRegContPtcp.mobslChnlCustNm = this.hoshInfoList[1].insrdNm // 모바일영업채널고객명
      mobpsRegContPtcp.MobpsContrRltnScCd = ' ' // 모바일청약계약자관계구분코드 : 모름
      mobpsRegContPtcp.mobpsSignMthCd = 'K' // 모바일청약서명방법코드
      mobpsRegContPtcp.coprPrtrMuagrYn = 'N' // 공동친권합의여부
      mobpsRegContPtcp.kakaoIapDspchSn = '0' // 알림톡알림발송순번

      mobpsRegContPtcpList.push(mobpsRegContPtcp)

      /** 계약관계자 구분코드 6:예금주 */
      mobpsRegContPtcp = {}
      mobpsRegContPtcp.contPtcpScCd = '6' // 계약관계인구분코드 / 6:예금주
      mobpsRegContPtcp.elstPartnNo = this.hoshInfoList[0].partnerId // 전자서명파트너번호, 법인
      mobpsRegContPtcp.mobslChnlCustId = this.hoshInfoList[1].agtFileId // 모바일영업채널고객ID
      mobpsRegContPtcp.mobslChnlCstgrId = this.hoshInfoList[1].detail.chnlCstgrId // 모바일영업채널고객그룹ID
      mobpsRegContPtcp.contPtcpTelnoEncr = this.$bizUtil.replaceAll(this.hoshInfoList[1].detail.celno, '-', '') // 계약관계인전화번호암호화
      mobpsRegContPtcp.contPtcpRrnEncr = this.hoshInfoList[0].custRrnId // 계약관계인주민등록번호암호화, 사업자등록번호
      mobpsRegContPtcp.mobslChnlCustNm = this.hoshInfoList[0].insrdNm // 모바일영업채널고객명, 사업자명
      mobpsRegContPtcp.MobpsContrRltnScCd = ' ' // 모바일청약계약자관계구분코드 : 모름
      mobpsRegContPtcp.mobpsSignMthCd = 'K' // 모바일청약서명방법코드
      mobpsRegContPtcp.coprPrtrMuagrYn = 'N' // 공동친권합의여부
      mobpsRegContPtcp.kakaoIapDspchSn = '0' // 알림톡알림발송순번

      mobpsRegContPtcpList.push(mobpsRegContPtcp)

      /** 계약관계자 구분코드 9:FC */
      if (this.$bizUtil.isEmpty(this.lv_fc.fcCelno)) {
        this.isMobpsValMessge = 'FC의 휴대폰 번호가 없습니다.'
        //this.isMobpsValCheck = true
        this.fn_IsAlertConfirmMessage(this.isMobpsValMessge)
        return
      }
      mobpsRegContPtcp = {}
      mobpsRegContPtcp.contPtcpScCd = '9' // 계약관계인구분코드 / 9:FC
      mobpsRegContPtcp.contPtcpTelnoEncr = this.$bizUtil.replaceAll(this.lv_fc.fcCelno, '-', '') // 계약관계인전화번호암호화
      mobpsRegContPtcp.mobslChnlCustNm = this.lv_fc.fcNm // 모바일영업채널고객명
      mobpsRegContPtcp.mobpsSignMthCd = this.urlTrnsTypFC[0].key // 모바일청약서명방법코드
      mobpsRegContPtcp.coprPrtrMuagrYn = 'N' // 공동친권합의여부
      mobpsRegContPtcp.kakaoIapDspchSn = '2' // 알림톡알림발송순번

      mobpsRegContPtcpList.push(mobpsRegContPtcp)

      // 계약관계자 목록 저장
      param.mobpsRegContPtcpList = mobpsRegContPtcpList

      ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //AML 대응 처리 추가된 컬럼 값 셋팅 전자서명 완료시 부터 AML 처리 기준값 
      let isAmlCntr      = PSAmlUtil.fn_IsAmlPrfmc('1',this.lv_InputData.jsonInputData, true) // 계약자 AML 대상여부
      let isAmlDthBnfr01 = PSAmlUtil.fn_IsAmlPrfmc('3',this.lv_InputData.jsonInputData) // 사망수익자01 AML 대상여부
      console.log('>>계약자 AML 대상여부:' + isAmlCntr)
      console.log('>>사망수익자01 AML 대상여부:' + isAmlDthBnfr01)
      console.log('>>param.elstPrtnNo :' + param.elstPartnNo)
      
      if(isAmlCntr) param.amlContrPartnNo	 = param.elstPartnNo // 계약자 BPID 셋팅
      if(isAmlDthBnfr01) {
        //전산심사결과 조회 에서 수익자정보 셋팅함 this.amlBnfrInfoList = event.bnfrInfoList  
        let dthBnfr01Info = await PSAmlUtil.fn_GetBnfrInfo(this,this.amlBnfrInfoList)
        console.log('>>dthBnfr01Info.partnerId :' + dthBnfr01Info.partnerId)
        console.log('>>dthBnfr01Info.agtFileId :' + dthBnfr01Info.agtFileId)
        console.log('>>dthBnfr01Info.custNm :' + dthBnfr01Info.custNm)
        if(dthBnfr01Info){
          param.amlDthBnfrPartnNo	   = dthBnfr01Info.partnerId	//AML사망수익자파트너번호
          param.amlDthBnfrChnlCustId = dthBnfr01Info.agtFileId	//AML사망수익자채널고객ID
          param.amlDthBnfrCustNm	   = dthBnfr01Info.custNm	//AML사망수익자고객명
        }
      }
      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      //console.log('>>모청발행:' + JSON.stringify(param) )
      // return

      // TTSD02003
      param.jsonInputData = JSON.stringify(this.lv_InputData.jsonInputData) // OZ jsonInputData

      this.post(lv_Vm, param, trnstId, auth,PSCommUtil.fn_getPostTssCommHeader(true))
        .then(function (response) {
          if (response.body != null) {
            //lv_Vm.isMobpsPblEnd = true
            //lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.mobpsPblEndMessage)
            //lv_Vm.fn_moveMobslList()

            // 사용자 이벤트 로그 기록 호출 함수
            // context : 호출 화면 this, curMobslSrnId : 호출화면ID, evtNm : 로그기록 이벤트명, vuchNo : 영수증번호
            PSCommUtil.prcsEvtLog(lv_Vm, 'MSPPS520M', '단체명의발송', lv_Vm.lv_InputData.moDCDocuMngVO.eltrnDoctId)

            lv_Vm.fn_BottomConfirmY(lv_Vm.mobpsPblEndMessage , true)
          } else {
            // 발행중 오류 발생시 메시지 표시
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
   /******************************************************************************
   * Function명 : fn_BottomConfirmY
   * 설명       : Confirm 팝업 호출
   ******************************************************************************/
   fn_BottomConfirmY (contents, isList) {
     this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
       properties: {
         title: '알림',
         content: contents,
         single: true
       },
       listeners: {
         onPopupConfirm: () => {
          this.$bottomModal.close(this.lv_AlertPop);
          if(isList) this.fn_moveMobslList()
          else this.$MenuManager.fn_RouterPushSrnId('MSPPI001M', {zaPlanId : this.lv_InputData.entplId})
         }
       }
     })
   },
    /************************************************************************************************
     * Function명  : fn_resntNmCheck
     * 설명        : 대표자 여부 확인
     ************************************************************************************************/
    fn_resntNmCheck () {
     let bpChk = false
     if( this.CorpInfo !== null && this.CorpInfo.tcrmGBobjNm.custRltnNm !== null ) {
        let item = this.CorpInfo.tcrmGBobjNm.custRltnNm.length
        for (let i = 0; i < item; i++) {
          if ( this.hoshInfoList[1].partnerId === this.CorpInfo.tcrmGBobjNm.custRltnNm[i].custRltnToVal) {
            bpChk = true
            break
          }
        }
     }

     if ( !bpChk ) {
       this.fn_BottomConfirmY(this.isResntNmCheckMessage, false) // '주피보험자가 법인대표와 일치하지 않습니다.<br>가입설계를 확인 후 다시 발행해 주시기 바랍니다.',
     } else {
      if (this.$bizUtil.compareCurrentAge(this.lv_insured.custRrnId.substr(0, 6), this.standardAge19)) {
        this.isResntNmCheck = true
      } else {
        this.isAlertConfirmMessage = this.isStandardAge19CheckMessage // '법인대표가 미성년자인경우 모바일청약을<br>진행할 수 없습니다.',
        this.fn_IsAlertConfirmMessage(this.isAlertConfirmMessage)
      }
     }
    },

    fn_resntNmCheck_ASIS () {
      // progress Bar 표시
      //window.vue.getStore('progress').dispatch('UPDATE', true)

      let serviceName = 'S1'

      // 단체정보 조회
      let pParams = {}
      pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS11S1'))
      pParams.context = this
      pParams.srnId = this.$options.screenId
      pParams.data = {}

      let inputJson = {}
      inputJson.bukrs = 'L001' // Company code  생명 L001, 화재 F100
      // inputJson.zaGSlctInqrScCd = this.selectGroups.groupValues.key
      // inputJson.zaGSlctInqrCondVal = zaGSlctInqrCondVal
      inputJson.zaGSlctInqrScCd = '02' // 01:단체코드, 02:사업자등록번호
      inputJson.zaGSlctInqrCondVal = this.hoshInfoList[0].custRrnId
      inputJson.spras = 'K' // 언어코드
      inputJson = JSON.stringify(inputJson)

      pParams.data.cmndSrvcNm = 'listGInfoList'
      pParams.data.sdrvDtVal = inputJson

      PSServiceManager.invoke(pParams, this.fn_ServiceResultHandler, serviceName, this.fn_ServiceFaultHandler, true)
    },
    /************************************************************************************************
     * Function명  : fn_ServiceDataResultHandler
     * 설명        : 서비스 호출 결과 함수 정의
     ************************************************************************************************/
    fn_ServiceResultHandler: function (event, serviceName) {
      let lv_data = event.data
      switch (serviceName) {
        case 'S1':
          if (lv_data !== null && lv_data.zGInfoDtoList !== null && lv_data.zGInfoDtoList[0] !== null) {
            let resntNm = lv_data.zGInfoDtoList[0].zaResntNm

            // 사업자등록번호로 조회한 대표자명과 주피보험자이름 검증
            if (resntNm === this.hoshInfoList[1].insrdNm ) {
              // 대표자가 미성년자인 경우 모바일청약 불가함
              if (this.$bizUtil.compareCurrentAge(this.lv_insured.custRrnId.substr(0, 6), this.standardAge19)) {
                this.isResntNmCheck = true
              } else {
                this.isAlertConfirmMessage = this.isStandardAge19CheckMessage
                //this.isAlertConfirm = true
                this.fn_IsAlertConfirmMessage(this.isAlertConfirmMessage)
              }
            } else {
              // this.isAlertConfirmMessage = this.isResntNmCheckMessage
              // this.isAlertConfirm = true
              // this.fn_IsAlertConfirmMessage(this.isAlertConfirmMessage)
              this.fn_BottomConfirmY(this.isResntNmCheckMessage, false)
            }
          } else {
            // this.isAlertConfirmMessage = this.isResntNmCheckMessage
            // this.isAlertConfirm = true
            // this.fn_IsAlertConfirmMessage(this.isAlertConfirmMessage)
            this.fn_BottomConfirmY(this.isResntNmCheckMessage, false)
          }
          break
        case 'S2': // (ASR240800968 24.10.22 ) tcrmGBobjNm.custRltnNm' 의 목록이  2개 이상이면 공동대표라고 판단하시면 됩니다
          this.CorpInfo = lv_data
          if (lv_data !== null && lv_data.tcrmGBobjNm.custRltnNm !== null && lv_data.tcrmGBobjNm.custRltnNm.length >= 2 ) {
            console.log('법인대표자건수:' + lv_data.tcrmGBobjNm.custRltnNm.length)
            this.btnView = false
          }
          break
        default:
          break
      }
    },
    /************************************************************************************************
     * Function명  : fn_ServiceFaultHandler
     * 설명        : 에러 이벤트
     ************************************************************************************************/
    fn_ServiceFaultHandler: function (event, serviceName) {
      switch (serviceName) {
        case 'S1':
          this.isMobpsValMessge = '고객정보 조회시 오류가 발생하였습니다.'
          break
        case 'S2':
          this.isMobpsValMessge = '대표자 조회시 오류가 발생하였습니다.'
          break
        default:
          break
      }
      this.fn_IsAlertConfirmMessage(this.isMobpsValMessge)
      //this.isMobpsValCheck = true
    },
    /************************************************************************************************
     * Function명  : fn_closeConfirm
     * 설명        : confirm 닫기
     ************************************************************************************************/
    // fn_closeConfirm () {
    //   this.isAlertConfirm = false
    // },
    /******************************************************************************
    * Function명 : fn_showTSSCM122D
    * 설명       : 고객개인정보 수정 팝업 열기
    ******************************************************************************/
    fn_showTSSCM122D (targetChnlCustId) {
      this.rpblPopup = this.$bottomModal.open(MSPCM300P, {
        properties: {
          callerId : this.$options.screenId, 
          viewId : 'MSPCM301D',
          pChnlCustId : targetChnlCustId,
          pCnsltNo : this.getStore('userInfo').getters.getUserInfo.userId,
        },
        listeners: { 
          // 대상팝업 onPopupClose $emit 이벤트명 
          onPopupClose : (rtnData) => {            
            this.fn_hideTSSCM122D()
            this.$bottomModal.close(this.rpblPopup) // 모달 닫기        

          },
          closePopup : (rtnData) => {            
            this.fn_hideTSSCM122D()
            this.$bottomModal.close(this.rpblPopup) // 모달 닫기   
          },
          onPopupConfirm: (rtnData) => {            
            this.fn_hideTSSCM122D()
            this.$bottomModal.close(this.rpblPopup) // 모달 닫기   

          },
        }
      },
      {
        properties: {
          noHeader: true
        }
      })
    },
    /******************************************************************************
    * Function명 : fn_hideTSSCM122D
    * 설명       : 고객개인정보 수정 팝업 닫기
    ******************************************************************************/
    fn_hideTSSCM122D () {
      console.log('팝업재')
      PSElstUtil.fn_EltrnSignBaseInfo('txTSSPS13S2', this.jsonString, this.fn_ServiceDataResultHandler)
    },

    fn_IsAlertConfirmMessage (msg) {
      this.getStore('confirm').dispatch('ADD', msg)
    },

    /******************************************************************************
    * Function명 : fn_selF1MDI0074
    * 설명       : 공동대표 여부 조회 F1MDI0074
    *            : 공동대표일 경우, 발행화면 내 '바로열기' 숨김 (알림톡 발송만 가능) / (ASR240800968 24.10.22  최주연)
    *              tcrmGBobjNm.custRltnNm' - 2개이상이면 공동대표로 판단
    ******************************************************************************/
    fn_selF1MDI0074 () {
      let serviceName = 'S2'

      // 단체정보 조회
      let pParams = {}
      pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS14S1'))
      pParams.data = {}
      let custAthntNm = {}
      custAthntNm.custId = this.hoshInfoList[0].partnerId // 전자서명파트너번호
      custAthntNm.knbScCd = '21'
      pParams.data.custAthntNm = custAthntNm
      PSServiceManager.invoke(pParams, this.fn_ServiceResultHandler, serviceName, this.fn_ServiceFaultHandler, true)
    },

  //--------------------------------------------------------------------------------
    _fn_End(){return}//코딩 종료 함수 마지막 메서드 함수 , 없음 주의
 },// methods

}//export default


</script>
<style scoped>
</style>